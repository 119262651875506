const HISTORY_CHANGE_GLOBAL_EVENT = 'ns:historychange';
const POP_STATE = 'popstate';
const PUSH_STATE = 'pushstate';
const REPLACE_STATE = 'replacestate';

export type HistoryChangeEventType =
  | typeof POP_STATE
  | typeof PUSH_STATE
  | typeof REPLACE_STATE;

export type HistoryChangeEvent = CustomEvent<{
  type: HistoryChangeEventType;
}>;

const dispatchHistoryChangeEvent = (event: HistoryChangeEventType) =>
  window.dispatchEvent(
    new CustomEvent(HISTORY_CHANGE_GLOBAL_EVENT, {
      detail: { type: event }
    }) as HistoryChangeEvent
  );

const wrapAndEmitEvent = (
  hist: History,
  prop: keyof History,
  event: HistoryChangeEventType
) => {
  const orig = hist[prop] as (...args: any[]) => void;
  (hist as any)[prop] = function (...args: any[]) {
    orig.apply(this, args);
    dispatchHistoryChangeEvent(event);
  };
};

const listen = (event: string, listener: (event: any) => void) =>
  window.addEventListener(event, listener);

// The `ensurePatchedHistory` function modifies global history / window. We need
// to ensure that it can run multiples times in a js context or on a history obj
// from potentially multiple copies of this history listener module as it could be
// depended on by multiple modules. We CANNOT have side effects of multiple runs
const historyPatchedSymbol = Symbol.for('ns.history.patched');
const ensurePatchedHistory = (hist: History) => {
  if (!(hist as any)[historyPatchedSymbol]) {
    (hist as any)[historyPatchedSymbol] = true;

    wrapAndEmitEvent(hist, 'pushState', PUSH_STATE);
    wrapAndEmitEvent(hist, 'replaceState', REPLACE_STATE);
  }

  if (!(window as any)[historyPatchedSymbol]) {
    (window as any)[historyPatchedSymbol] = true;
    listen(POP_STATE, () => dispatchHistoryChangeEvent(POP_STATE));
  }
};

/**
 * Add a listener to changes on a given history object
 *
 * Receives a HistoryChangeEvent with a type indicator of
 * HistoryChangeEventType to distinguish between the reason of the change
 */
export const addHistoryListener = (
  hist: History,
  listener: (event: HistoryChangeEvent) => void
): /* off */ (() => void) => {
  ensurePatchedHistory(hist);

  listen(HISTORY_CHANGE_GLOBAL_EVENT, listener as any);

  return () =>
    window.removeEventListener(HISTORY_CHANGE_GLOBAL_EVENT, listener as any);
};
