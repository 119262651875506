const hash = 'hash';
const host = 'host';
const hostname = 'hostname';
const href = 'href';
const origin = 'origin';
const pathname = 'pathname';
const port = 'port';
const protocol = 'protocol';
const search = 'search';

type LocationSnapshotProp =
  | typeof hash
  | typeof host
  | typeof hostname
  | typeof href
  | typeof origin
  | typeof pathname
  | typeof port
  | typeof protocol
  | typeof search;

/**
 * A snapshot in time of a location. Contains all relevant window.location props.
 * They will not change, and they are readonly
 */
export type LocationSnapshot = Readonly<Pick<Location, LocationSnapshotProp>>;

const locationSnapshotProps: LocationSnapshotProp[] = [
  hash,
  host,
  hostname,
  href,
  origin,
  pathname,
  port,
  protocol,
  search
];

/**
 * Given a Location object, optionally defaulting to the global location,
 * make a snapshot of that location object. Contains only properties, no
 * mutating methods. Every property is readonly
 */
export const getLocationSnapshot = (
  loc: Location = location
): LocationSnapshot =>
  Object.freeze(
    locationSnapshotProps.reduce((snapshot, prop) => {
      snapshot[prop] = loc[prop];
      return snapshot;
    }, {} as any)
  );
